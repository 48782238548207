import React from "react";

export default function IconText(props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "inherit",
        // justifyContent: "center",
      }}
    >
      {props.icon}
      &nbsp;
      {props.text}
    </div>
  );
}
