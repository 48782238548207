import React, { useEffect ,useState} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Link,
  Avatar,
  Stack,
  useMediaQuery,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { deepPurple } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { baseUrl } from "../../Apis/index";

const ScheduledEvents = ({ custumerId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        // Replace the URL with the actual endpoint from which you're fetching bookings
        const response = await fetch(baseUrl + "/booking?id=" + custumerId);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBookings(data);
      } catch (error) {
        console.error('There was a problem with your fetch operation:', error);
      }
    };

    fetchBookings();
  }, []); // The empty array ensures this effect runs only once after the initial render

  const categorizeBookingsByDate = (bookings) => {
    const categorized = {};

    bookings.forEach((booking) => {
      const bookingDate = booking.booking_date.split('T')[0]; // Extract just the date part
      if (!categorized[bookingDate]) {
        categorized[bookingDate] = [];
      }
      categorized[bookingDate].push(booking);
    });

    return categorized;
  };

  const categorizedBookings = categorizeBookingsByDate(bookings);


  const styles = {
    container: isMobile
      ? {
          flexDirection: "column",
          //   alignItems: "center",
        }
      : {
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
        },
    timeAvatar: {
      bgcolor: deepPurple[500],
      marginRight: 2,
    },
    timeText: {
      fontWeight: "bold",
      marginRight: 2,
    },
    nameText: {
      fontWeight: "bold",
      flexGrow: 1,
    },
    detailsText: {
      color: "text.secondary",
      marginRight: 2,
    },
    hostsText: {
      color: "text.secondary",
      whiteSpace: "nowrap",
    },
  };

  const tableCellStyle = {
    borderBottom: "none",
    paddingTop: "12px",
    paddingBottom: "12px",
    "&:first-of-type": {
      fontWeight: "bold",
      paddingRight: "16px",
    },
    "&:last-of-type": {
      fontWeight: "normal",
    },
  };

  const linkStyle = {
    textDecoration: "none",
    fontWeight: "bold",
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };


  return (
    <Paper elevation={0}>
      {Object.entries(categorizedBookings).map(([date, bookingsForDate]) => {
        return (
          <Table>
            <TableHead sx={{ background: "#fafafa" }}>
              <TableRow>
                <TableCell>
                  <Typography sx={styles.timeText}>
                  {formatDate(date)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {bookingsForDate.map((booking) => {
                return (
                  <>
                    {/* <Box sx={{height:5}}/> */}

                    <TableRow>
                      <Accordion elevation={0}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box display="flex" justifyContent="center">
                            <Stack
                              direction={isMobile ? "column" : "row"}
                              spacing={isMobile ? 1 : 2}
                              sx={styles.container}
                            >
                              {isMobile ? (
                                <Stack direction="row" sx={{ pt: 1 }}>
                                  <Avatar sx={styles.timeAvatar}>{booking?.attendees_name[0].toUpperCase()}</Avatar>
                                  <Typography
                                    sx={{ ...styles.timeText, mt: 1 }}
                                  >
                                    {booking.booking_time}
                                    {/* 09:00am - 09:35am */}
                                  </Typography>
                                </Stack>
                              ) : (
                                <>
                                  <Avatar sx={styles.timeAvatar}>{booking?.attendees_name[0].toUpperCase()}</Avatar>
                                  <Typography sx={styles.timeText}>
                                    {/* 09:00am - 09:35am */}
                                    {booking.booking_time}
                                  </Typography>
                                </>
                              )}
                              <Box sx={{ height: 6 }} />

                              <Typography sx={styles.nameText}>
                                {booking.attendees_name}
                              </Typography>
                              <Typography sx={styles.detailsText}>
                                Event type - Google Meets
                              </Typography>
                              {!isMobile && (
                                <Typography sx={styles.hostsText}>
                                  1 host | 0 non-hosts
                                </Typography>
                              )}
                            </Stack>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  sx={tableCellStyle}
                                  component="th"
                                  scope="row"
                                >
                                  EMAIL
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                  {booking.attendees_email}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={tableCellStyle}
                                  component="th"
                                  scope="row"
                                >
                                  LOCATION
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                  This is a Google Meet web conference.
                                  <Link href={booking.meeting_link} sx={linkStyle}>
                                    Join now
                                  </Link>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={tableCellStyle}
                                  component="th"
                                  scope="row"
                                >
                                  INVITEE TIME ZONE
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                  {booking.time_zone}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={tableCellStyle}
                                  component="th"
                                  scope="row"
                                >
                                  QUESTIONS
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                  {booking.attendees_note == ""
                                    ? "Please share anything that will help prepare for our meeting ."
                                    : booking.attendees_note}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={tableCellStyle}
                                  component="th"
                                  scope="row"
                                >
                                  MEETING HOST
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                  Host will attend this meeting
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    </TableRow>
                    <Divider variant="middle" sx={{ background: "#fafafa" }} />
                  </>
                );
              })}
            </TableBody>
          </Table>
        );
      })}
    </Paper>
  );
};

export default ScheduledEvents;
