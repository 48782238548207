import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const CustomTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1976d2",
  },
});

const CustomTab = styled(Tab)({
  textTransform: "none",
  minWidth: 0,
  fontWeight: 600,
  marginRight: "16px",
  color: "rgba(0, 0, 0, 0.54)",
  "&.Mui-selected": {
    color: "#1976d2",
  },
});

const MyModal = ({ open, handleClose, link,title }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
           {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <CustomTabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="modal tabs"
        >
          <CustomTab
            label="Share"
            style={{
              outline: "none", // Remove the outline on active (click)
              boxShadow: "none", // Remove the box-shadow on active (click)
            }}
          />
          <CustomTab
            label="Add to Website"
            style={{
              outline: "none", // Remove the outline on active (click)
              boxShadow: "none", // Remove the box-shadow on active (click)
            }}
          />
        </CustomTabs>
        {tabValue === 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography id="modal-description">
              Copy and paste your scheduling link into a message
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              value={link}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => copyToClipboard(link)}
            >
              Copy Link
            </Button>
            {/* <FormControlLabel
              control={<Switch defaultChecked />}
              label="Let this link expire after the first booking"
              sx={{ mt: 2 }}
            /> */}
          </Box>
        )}
        {tabValue === 1 && (
          <Box sx={{ mt: 2 }}>
            <Typography id="modal-description">
              Add times to your website
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              value={`<iframe src="${link}" width='100%' height='600'></iframe>`}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 2 }}
              onClick={() =>
                copyToClipboard(
                  `<iframe src="${link}" width='100%' height='600'></iframe>`
                )
              }
            >
              Copy Code
            </Button>
          </Box>
        )}
        {/* <Button variant="outlined" fullWidth sx={{ mt: 2 }}>
          Customize & Share
        </Button> */}
      </Box>
    </Modal>
  );
};

export default MyModal;
