import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./header";
import moment from "moment-timezone";
import useRazorpay from "react-razorpay";
import {
  Box,
  Container,
  Grid,
  Autocomplete,
  TextField,
  createFilterOptions,
  Button,
  Divider,
} from "@mui/material";
import Lottie from "lottie-react";
import toast from "react-hot-toast";

import { baseUrl } from "../../Apis/index";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import { PaymentTwoTone } from "@mui/icons-material";

import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeTwoTone";
import VideocamTwoToneIcon from "@mui/icons-material/VideocamTwoTone";
import { useHistory, useParams } from "react-router-dom";
import Spacer from "../../components/Spacer";
import timezome from "../../timezones.json";
import "./Ribbon.css";
import IconText from "../../components/IconText";
import loaddingIcon from "./assets/3-dots-scale.svg";
import tickCnf from "./assets/Comp 1.json";
import { useQuery } from "react-query";
import { fetchData } from "../../Apis/EventForm";

export default function Calender() {
  const { userLink, eventLink, id } = useParams();
  const [nextx, setNext] = React.useState(false);
  const [paymentId, setPaymentId] = React.useState("");
  const [Razorpay] = useRazorpay();
  const { data: eventData, isLoading } = useQuery("eventData1", () =>
    fetchData(id)
  );

  React.useEffect(() => {
    if (isLoading == false) {
      let RawStartDate = dayjs(eventData[0].StartDate);
      let StartDate = RawStartDate.toString().slice(4, 16);

      // console.log(eventData[0]?.Schedule)
    } else {
    }
  }, [eventData]);

  function generateTimeArray(timeSlots, interval) {
    // Simplified formatting function for 12-hour format with AM/PM
    function formatTime(hours, minutes) {
      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight
      minutes = minutes < 10 ? "0" + minutes : minutes;
      return `${hours}:${minutes}${ampm}`;
    }

    // Parse start and end times in 24-hour format
    function parseTime(timeString) {
      let [hour, minute] = timeString.split(":").map(Number);
      return [hour, minute];
    }

    const times = [];

    for (const slot of timeSlots) {
      const [startHour, startMinute] = parseTime(slot.start);
      const [endHour, endMinute] = parseTime(slot.end);

      let currentHour = startHour;
      let currentMinute = startMinute;

      while (true) {
        // Check if the current time is within the range
        const currentTimeInMinutes = currentHour * 60 + currentMinute;
        const startTimeInMinutes = startHour * 60 + startMinute;
        const endTimeInMinutes = endHour * 60 + endMinute;

        const isWithinTimeRange =
          (startTimeInMinutes <= endTimeInMinutes &&
            currentTimeInMinutes >= startTimeInMinutes &&
            currentTimeInMinutes <= endTimeInMinutes) ||
          (startTimeInMinutes > endTimeInMinutes &&
            (currentTimeInMinutes >= startTimeInMinutes ||
              currentTimeInMinutes <= endTimeInMinutes));

        if (isWithinTimeRange) {
          times.push(formatTime(currentHour, currentMinute));
        }

        // Break the loop if we reach the end time and passed it
        if (currentHour === endHour && currentMinute === endMinute) {
          if (!times.includes(formatTime(endHour, endMinute))) {
            times.push(formatTime(endHour, endMinute));
          }
          break;
        }

        // Update current time
        currentMinute += interval;
        if (currentMinute >= 60) {
          currentHour += Math.floor(currentMinute / 60);
          currentMinute %= 60;
        }
        if (currentHour >= 24) {
          currentHour = 0;
        }

        // Additional safeguard to prevent infinite loop
        if (
          currentHour === startHour &&
          currentMinute === startMinute &&
          times.length > 0
        ) {
          break;
        }
      }
    }

    return times;
  }

  const [timeSlot, setTimeSlot] = React.useState([]);
  const [lastTimeSlot, setlastTimeSlot] = React.useState([]);
  const [timezoneValue, setTimezone] = React.useState(timezome[0]);
  const [datevalue, setDateValue] = React.useState(null);
  const [sides, setSides] = React.useState({
    left: 6,
    center: 6,
    right: 0,
    boxSize: "md",
    display: "none",
  });

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.value,
  });

  function handleCalender(newValue) {
    setDateValue(newValue);
    // console.log(newValue.format('ddd'));
    // console.log(eventData[0]?.Schedule[0][newValue.format('ddd').toUpperCase()])
    let time = eventData[0]?.Schedule[0][newValue.format("ddd").toUpperCase()];

    // console.log(time);
    setTimeSlot(generateTimeArray(time, parseInt(eventData[0]?.Duration, 10)));
    setSides({ left: 4, center: 4.5, right: 3.5 });
  }

  // form Screen Set
  const [screen, setScreen] = React.useState(true);
  const [confPage, setConfPage] = React.useState(true);
  const [loading, setLoding] = React.useState(false);

  function formatDateRangeWithWeekdays(startDate, endDate, weekdays) {
    var start = new Date(startDate);
    var end = new Date(endDate);
    var dateArray = [];
    var currentDate = start;
    var weekdayMap = {
      SUN: 0,
      MON: 1,
      TUE: 2,
      WED: 3,
      THU: 4,
      FRI: 5,
      SAT: 6,
    };
    var filteredWeekdays = weekdays.map((day) => weekdayMap[day]);

    function getFormattedDate(date) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let day = date.getDate();
      let monthIndex = date.getMonth();
      let year = date.getFullYear();

      return (
        monthNames[monthIndex] + " " + (day < 10 ? "0" + day : day) + " " + year
      );
    }

    while (currentDate <= end) {
      if (filteredWeekdays.includes(currentDate.getDay())) {
        dateArray.push(getFormattedDate(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  }

  // booking

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    note: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  if (isLoading == true) {
    return <></>;
  }

  function booking(
    CustomerId,
    summary,
    description,
    startDate,
    endDate,
    time_zone
  ) {
    // console.log(moment.utc(startDate).format('YYYY-MM-DDTHH:mm:ss')+formatTimezoneOffset(timezoneValue?.offset));
    // create meeting

    axios
      .post(baseUrl + "/auth/create-meet-new", {
        CustomerId: CustomerId,
        summary: summary,
        description: description,
        startDate:
          moment.utc(startDate).format("YYYY-MM-DDTHH:mm:ss") +
          formatTimezoneOffset(timezoneValue?.offset),
        endDate:
          moment.utc(endDate).format("YYYY-MM-DDTHH:mm:ss") +
          formatTimezoneOffset(timezoneValue?.offset),
        attendees_email: formData.email,
        time_zone: timezoneValue?.utc[0],
      })
      .then(async (response) => {
        console.log("Meet link created:", response.data.hangoutLink);
        // Do something with the Meet link

        const url = baseUrl + "/booking"; // Replace with your API endpoint
        const data = {
          // Your data here
          event_id: id,
          booking_date: datevalue,
          booking_time: timeSlot[0],
          time_zone: timezoneValue?.utc[0],
          payment: "null",
          attendees_name: formData.name,
          attendees_email: formData.email,
          attendees_note: formData.note,
          meeting_link: response.data.hangoutLink,
        };

        console.log(data);

        try {
          const response = await axios.post(url, data);
          console.log(response.data);
          // show confermation screen
          setTimeout(() => {
            setConfPage(false);
            setSides({
              left: 6,
              center: 6,
              right: 0,
              boxSize: "md",
              display: "none",
            });
          }, 2000);
          // show confermation screen
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => console.error("Error creating Meet link", error));
    // create meeting
  }

  // booking

  // Example input
  let startDate = eventData[0].StartDate;
  let endDate = eventData[0].EndDate;
  let weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  let availableDates = formatDateRangeWithWeekdays(
    startDate,
    endDate,
    weekdays
  );

  // console.log(availableDates)
  // let availableDates= [
  //   "Aug 11 2023",
  //   "Aug 12 2023",
  //   "Aug 13 2023",
  // ];

  function disableRandomDates(datef) {
    // let x = Math.random() > 0.7;
    let dayx = datef.toDate();
    let day = dayx.toString().slice(4, 15);
    let mode = true;
    // console.log(day);
    availableDates.map((val) => {
      // console.log(val + "=" + day);
      if (val == day) {
        // console.log("matched");
        mode = false;
      }
    });

    return mode;
  }

  // date time add function

  function combineDateAndTime(dateStr, timeStr) {
    // Parse the ISO date string
    const date = new Date(dateStr);

    // Extract hours and minutes from the AM/PM time string
    const timeParts = timeStr.match(/(\d+):(\d+)(am|pm)/i);
    let hours = parseInt(timeParts[1], 10);
    const minutes = parseInt(timeParts[2], 10);
    const ampm = timeParts[3].toLowerCase();

    // Adjust hours for 12-hour AM/PM format
    if (ampm === "pm" && hours < 12) hours += 12;
    if (ampm === "am" && hours === 12) hours = 0;

    // Set the hours and minutes on the date object
    date.setUTCHours(hours, minutes, 0, 0);

    return date;
  }

  // Example usage
  // const dateStr = '2024-01-20T18:30:00.000Z';
  // const timeStr = '9:30am';
  // const combinedDateTime = combineDateAndTime(dateStr, timeStr);
  // console.log(combinedDateTime.toISOString());

  function addMinutesToDateStr(dateStr, timeStr) {
    const date = new Date(dateStr);
    const minutesToAdd = parseInt(timeStr.split(" ")[0], 10);
    date.setMinutes(date.getMinutes() + minutesToAdd);
    return date.toISOString();
  }

  // Example usage
  // const dateStr = '2024-01-20T18:30:00.000Z';
  // const timeStr = "30 minutes";
  // const newDateTime = addMinutesToDateStr(dateStr, timeStr);
  // console.log(newDateTime);

  function formatTimezoneOffset(offset) {
    const sign = offset >= 0 ? "+" : "-";
    const absoluteOffset = Math.abs(offset);
    const hours = Math.floor(absoluteOffset);
    const minutes = (absoluteOffset - hours) * 60;

    // Pad hours and minutes with leading zeros if necessary
    const paddedHours = String(hours).padStart(2, "0");
    const paddedMinutes = String(minutes).padStart(2, "0");

    return `${sign}${paddedHours}:${paddedMinutes}`;
  }

  // razorpay

  const handlePayment = async (
    CustomerId,
    EventName,
    Description,
    combineDateAndTime,
    addMinutesToDateStr
  ) => {
    // const order = await createOrder(params); //  Create order on your backend

    const options = {
      // rzp_test_TSygxSjfFpijgy
      // rzp_live_O5uSTwEHv8IIS4
      key: eventData[0].payment_gateway_api_key, // Enter the Key ID generated from the Dashboard
      amount: eventData[0].event_fee * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: eventData[0].currency,
      // name: "Acme Corp",
      // description: "Test Transaction",
      // image: "https://example.com/your_logo",
      // order_id: "566779", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // console.log(response);
        setPaymentId(response.razorpay_payment_id);
        booking(
          CustomerId,
          EventName,
          Description,
          combineDateAndTime,
          addMinutesToDateStr
        );
        setLoding(true);

        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      toast.success("Payment Failed Please Retry!");

      // alert(response.error.code);
      // alert(response.error.description);3
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };

  return (
    <div>
      {/* <Header shadow={true} /> */}
      <Spacer value={90} />
      <Container maxWidth={sides.boxSize}>
        <Box
          className="box"
          sx={{
            display: "flex",
            width: "100%",
            minHeight: "620px",
            boxShadow:
              "0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 0px 20px 0 rgba(0, 0, 0, 0.13)",
            "@media (max-width: 600px)": {
              mb: 10,
              pb: 10,
            },
          }}
        >
          <div className="ribbon ribbon-top-left">
            <a href="https://caley.app">
              <span>
                <b style={{ fontSize: "8px" }}>
                  POWERED BY&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </b>
                <b
                  style={{
                    fontSize: "12px",
                    display: "block",
                  }}
                >
                  {" "}
                  Caley&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </b>
              </span>
            </a>
          </div>
          {confPage ? (
            <Grid container>
              <Grid
                item
                md={sides.left}
                xs={12}
                sx={{
                  borderRight: 1.8,
                  borderColor: "#e8e8e8",
                  textAlign: "left",
                }}
              >
                <Spacer value={60} />
                <Box mx={6}>
                  <span className="title1">
                    {userLink.replaceAll("-", " ")}
                  </span>
                  <span className="heading1">{eventData[0].EventName}</span>
                  <Spacer value={1} />

                  <IconText
                    icon={<AccessTimeFilledOutlinedIcon fontSize="small" />}
                    text={
                      <span className="title2">{eventData[0].Duration}</span>
                    }
                  />
                  <IconText
                    icon={
                      <VideocamTwoToneIcon fontSize="small" sx={{ mt: -1 }} />
                    }
                    text={
                      <span className="title2" style={{ paddingTop: 12 }}>
                        Web conferencing details provided upon confirmation.
                      </span>
                    }
                  />
                  {datevalue && (
                    <IconText
                      icon={
                        <CalendarTodayTwoToneIcon
                          fontSize="small"
                          sx={{ mt: 1 }}
                        />
                      }
                      text={
                        <span className="title2" style={{ paddingTop: 12 }}>
                          {datevalue.toString().slice(0, 17)}
                        </span>
                      }
                    />
                  )}

                  <Box my={0.9} />
                  {timezoneValue?.value && (
                    <IconText
                      icon={<PublicTwoToneIcon fontSize="small" />}
                      text={
                        <span className="title2 ">{timezoneValue?.value}</span>
                      }
                    />
                  )}
                  <Box my={0.9} />
                  {eventData[0].Payment == "na" || null ? (
                    <></>
                  ) : (
                    <IconText
                      icon={<PaymentTwoTone fontSize="small" />}
                      text={
                        <span className="title2 ">
                          {eventData[0].currency == "INR" ? "₹" : ""}{" "}
                          {eventData[0].event_fee} {eventData[0].currency}
                        </span>
                      }
                    />
                  )}
                </Box>
              </Grid>
              {screen ? (
                <>
                  <Grid item md={sides.center} xs={12}>
                    <Spacer value={10} />
                    <p className="title1">Choose your Date & Time</p>
                    <Spacer value={20} />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        value={datevalue}
                        shouldDisableDate={disableRandomDates}
                        sx={{
                          "& .Mui-selected ": {
                            backgroundColor: "#18ae6b!important",
                            borderColor: "#fff",
                            color: "#fff!important",
                          },
                          "& .MuiPickersDay-root": {
                            backgroundColor: "rgba(24,174,109,0.09)",
                            color: "#0060e6",
                            fontWeight: "bold",
                            fontSize: "13px",
                            margin: "2px!important",
                          },
                          "& .Mui-disabled": {
                            backgroundColor: "#fff!important",
                          },
                          "& .MuiDayCalendar-weekDayLabel": {
                            margin: "2px!important",
                          },
                        }}
                        onChange={(newValue) => handleCalender(newValue)}
                      />
                    </LocalizationProvider>

                    <Box
                      sx={{
                        px: 9,
                        textAlign: "left",
                        "@media (max-width: 600px)": {
                          px: 4,
                        },
                      }}
                    >
                      <span className="title2">TIME ZONE</span>

                      <Autocomplete
                        size="small"
                        fullWidth
                        id="filter-demo"
                        options={timezome}
                        getOptionLabel={(option) => option.value}
                        filterOptions={filterOptions}
                        sx={{
                          mt: 1,
                        }}
                        value={timezoneValue}
                        onChange={(event, newValue) => {
                          setTimezone(newValue);
                          // console.log(newValue);
                        }}
                        renderInput={(params, option) => (
                          <TextField {...params} label="Select time zone" />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={sides.right}
                    xs={12}
                    sx={{
                      display: sides.display,
                      overflow: "hidden",
                      overflowY: "scroll",
                      height: "535px",
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "left",
                        mt: 16,
                        mr: 8,
                        pl: 1,
                        "@media (max-width: 600px)": {
                          mr: 0,
                          pl: 0,
                          mx: 4,
                          mt: 5,
                        },
                      }}
                    >
                      {timeSlot.map((time, index) => {
                        return (
                          <>
                            <Button
                              key={index + "btn"}
                              fullWidth
                              variant="outlined"
                              size="large"
                              sx={{
                                mb: 1.2,
                                p: 1.5,
                                "&:hover": {
                                  border: 1.4,
                                  borderColor: "#18ae6b",
                                },
                              }}
                              onClick={() => {
                                setlastTimeSlot(timeSlot);
                                setTimeSlot([time]);
                                setNext(true);
                              }}
                            >
                              <span className="btn-text w-50">{time}</span>
                            </Button>

                            <br />
                            {nextx ? (
                              <div style={{ marginTop: -1 }}>
                                <Button
                                  variant="text"
                                  size="small"
                                  sx={{
                                    p: 1.5,
                                    width: "48.3%",
                                    display: "inline",
                                    background: "#666666",
                                    color: "#fff",
                                    "&:hover": {
                                      backgroundColor: "#666666",
                                      color: "#fff",
                                    },
                                  }}
                                  onClick={() => {
                                    // setTimeSlot(timedata);

                                    setTimeSlot(lastTimeSlot);
                                    setNext(false);
                                  }}
                                >
                                  <span className="btn-text w-50">BACK</span>
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="text"
                                  size="small"
                                  sx={{
                                    p: 1.5,
                                    width: "48.3%",
                                    display: "inline",
                                    background: "#18ae6b",
                                    color: "#fff",
                                    "&:hover": {
                                      backgroundColor: "#1876d2",
                                      color: "#fff",
                                    },
                                  }}
                                  onClick={() => {
                                    setScreen(false);
                                  }}
                                >
                                  <span className="btn-text w-50">NEXT</span>
                                </Button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                    </Box>
                  </Grid>
                </>
              ) : (
                // Second Screen
                <>
                  <Grid item md={8} xs={12}>
                    <Spacer value={10} />
                    <Box
                      sx={{
                        px: 5,
                        textAlign: "left",
                      }}
                    >
                      <p className="title1">Enter Details</p>
                      <Spacer value={20} />
                      <form>
                        <p className="title2 m-0 mt-2">Name *</p>
                        <TextField
                          type="text"
                          size="medium"
                          sx={{
                            width: "53%",
                            mt: 0.6,
                            "@media (max-width: 600px)": {
                              width: "100%",
                            },
                          }}
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                        <p className="title2 m-0 mt-2">Email *</p>
                        <TextField
                          type="email"
                          size="medium"
                          sx={{
                            width: "53%",
                            mt: 0.6,
                            "@media (max-width: 600px)": {
                              width: "100%",
                            },
                          }}
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        <p className="lable-text m-0 mt-3">
                          Please share anything that will help prepare for our
                          meeting.
                        </p>
                        <TextField
                          type="text"
                          size="medium"
                          multiline
                          rows={3}
                          sx={{
                            width: "53%",
                            mt: 0.8,
                            "@media (max-width: 600px)": {
                              width: "100%",
                            },
                          }}
                          name="note"
                          value={formData.note}
                          onChange={handleInputChange}
                        />
                        <br /> <br />
                        <Button
                          variant="text"
                          size="small"
                          sx={{
                            p: 1.5,
                            width: "26%",
                            display: "inline",
                            background: "#666666",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "#666666",
                              color: "#fff",
                            },
                            "@media (max-width: 600px)": {
                              width: "47%",
                            },
                          }}
                          onClick={() => {
                            // setTimeSlot(timedata);
                            setTimeSlot(lastTimeSlot);
                            setNext(false);
                            setScreen(true);
                            setLoding(false);
                          }}
                        >
                          <span className="btn-text w-50">BACK</span>
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          variant="text"
                          size="small"
                          sx={{
                            p: 0.4,
                            width: "26%",
                            display: "inline",
                            background: "#1876d2",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "#1876d2",
                              color: "#fff",
                            },
                            "@media (max-width: 600px)": {
                              width: "47%",
                            },
                          }}
                          onClick={() => {
                            if (eventData[0].Payment != "na") {
                              handlePayment(
                                eventData[0].CustomerId,
                                eventData[0].EventName,
                                eventData[0].Description,
                                combineDateAndTime(
                                  datevalue,
                                  timeSlot[0]
                                ).toISOString(),
                                addMinutesToDateStr(
                                  combineDateAndTime(
                                    datevalue,
                                    timeSlot[0]
                                  ).toISOString(),
                                  eventData[0].Duration
                                )
                              );
                            } else {
                              booking(
                                eventData[0].CustomerId,
                                eventData[0].EventName,
                                eventData[0].Description,
                                combineDateAndTime(
                                  datevalue,
                                  timeSlot[0]
                                ).toISOString(),
                                addMinutesToDateStr(
                                  combineDateAndTime(
                                    datevalue,
                                    timeSlot[0]
                                  ).toISOString(),
                                  eventData[0].Duration
                                )
                              );
                              setLoding(true);
                            }
                          }}
                        >
                          {loading ? (
                            <img src={loaddingIcon} height={40} />
                          ) : (
                            <span className="btn-text ">
                              <img height={40} />
                              Book Now
                            </span>
                          )}
                        </Button>
                      </form>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <Grid container>
              <Grid item md={12} mt={4}>
                <center>
                  <Lottie
                    animationData={tickCnf}
                    loop={true}
                    style={{ height: "100px", width: "100px" }}
                  />
                  <span className="heading2">Confirmed</span>
                  <p className="title mt-1">
                    You are scheduled with {userLink.replaceAll("-", " ")}.
                  </p>
                  <Divider variant="middle" light />
                  <Box
                    sx={{
                      textAlign: "left",
                      ml: 28,
                      mt: 3,
                      "@media (max-width: 600px)": {
                        ml: 5,
                      },
                    }}
                  >
                    <IconText
                      icon={<AccessTimeFilledOutlinedIcon fontSize="small" />}
                      text={<span className="title2">1 Hours</span>}
                    />
                    <IconText
                      icon={
                        <CalendarTodayTwoToneIcon
                          fontSize="small"
                          sx={{ mt: 1 }}
                        />
                      }
                      text={
                        <span className="title2" style={{ paddingTop: 12 }}>
                          {datevalue.toString().slice(0, 17)}
                        </span>
                      }
                    />
                    <Box my={1} />
                    <IconText
                      icon={<PublicTwoToneIcon fontSize="small" />}
                      text={
                        <span className="title2 ">{timezoneValue?.value}</span>
                      }
                    />
                    <IconText
                      icon={
                        <VideocamTwoToneIcon fontSize="small" sx={{ mt: 1 }} />
                      }
                      text={
                        <span className="title2" style={{ paddingTop: 12 }}>
                          Web conferencing details to follow.
                        </span>
                      }
                    />
                  </Box>
                  <Spacer value={2} />
                  <span className="btn-text text-center ">
                    A calendar invitation has been sent to your email address.
                  </span>
                </center>
                <Spacer value={10} />
                <Divider variant="middle" light></Divider>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </div>
  );
}
