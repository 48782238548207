import { create } from "zustand";

function LSGD(key) {
  return JSON.parse(localStorage.getItem(key));
}
function LSSD(key, data) {
  localStorage.setItem(key, data);
}

export const useBearStore = create((set) => ({
  formData: LSGD("form") || {
    EventName: "",
    EventColor: "a",
  },
  setForm: (data) =>
    set((state) => {
      console.log(data);
      LSSD("form", JSON.stringify(data));
      return {
        formData: data,
      };
    }),
  clearForm: () => {
    set({ formData: {} });
    localStorage.removeItem("form");
  },
}));
