import React from "react";
import enGB from "date-fns/locale/en-GB";
import "dayjs/locale/de";
import { FindIn } from "../../utils/core";
import Header from "../../components/header";
import SubHeader from "../../components/subheader";

import dayjs from "dayjs";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { Typography, Tooltip, ListItemIcon, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AddIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { useBearStore } from "../../store/EventForm";
import { useNavigate } from "react-router-dom";
import timezome from "../../timezones.json";

import StepperBlock from "../../components/stepper";
import PopupBox from "../../components/popupbox";

export default function TimeSlot() {
  const { formData, setForm } = useBearStore();
  let navigate = useNavigate();
  const [selectedValue, setSelectedValue] = React.useState(
    formData.Duration || "30 min"
  );

  const [showInput, SetshowInput] = React.useState(
    formData.Duration == "Custom" ? "" : "none"
  );

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "Custom") {
      SetshowInput("");
    } else {
      SetshowInput("none");
    }

    setForm({
      ...formData,
      Duration: event.target.value,
    });
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.value,
  });

  const handleToggle = (value) => () => {
    const currentIndex = inputList[0]["DAY"].indexOf(value);

    if (currentIndex === -1) {
      inputList[0]["DAY"].push(value);
    } else {
      inputList[0]["DAY"].splice(currentIndex, 1);
    }
    const list = [...inputList];
    setForm({ ...formData, Schedule: list });
  };

  // Time Schadule
  const [inputList, setInputList] = React.useState(
    formData.Schedule || [
      {
        MON: [{ start: "09:00", end: "17:00" }],
        TUE: [{ start: "09:00", end: "17:00" }],
        WED: [{ start: "09:00", end: "17:00" }],
        THU: [{ start: "09:00", end: "17:00" }],
        FRI: [{ start: "09:00", end: "17:00" }],
        SAT: [{ start: "09:00", end: "17:00" }],
        SUN: [{ start: "09:00", end: "17:00" }],
        DAY: ["MON", "TUE", "WED", "THU", "FRI"],
      },
    ]
  );

  const WeekDays = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  // handle input change
  const handleInputChange = (e, index, KeyData) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[0][KeyData][index][name] = value;
    setInputList(list);
    setForm({ ...formData, Schedule: list });
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index, KeyData) => {
    inputList[0][KeyData].splice(index, 1);
    const obj = [...inputList];
    setInputList(obj);
    setForm({ ...formData, Schedule: obj });
  };

  // handle click event of the Add button
  const handleAddClick = (index, KeyData) => {
    inputList[0][KeyData].push({ start: "09:00", end: "17:00" });
    const obj = [...inputList];
    setInputList(obj);
    setForm({ ...formData, Schedule: obj });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);

    setForm({
      ...formData,
      TimeZonex: timezome[0],
      Schedule: inputList,
      StartDatex: dayjs(),
      EndDatex: dayjs(),
      Durationx: "30 minutes",
      Notificationx: "30 minutes",
    });
  }, []);

  function handleForm(e) {
    e.preventDefault();
    navigate("/final-page", { replace: false });
  }

  return (
    <div>
      <Header />
      <SubHeader data={"Schedule Event"} />
      <Box mt={12} />
      <form
        name="scheduleForm"
        onSubmit={(e) => {
          handleForm(e);
        }}
      >
        <Container component="main" maxWidth="md">
          <StepperBlock no={1} />
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              py: 3,
              px: 6,
              background: "#fff",
              border: 2.1,
              borderRadius: "3px",
              borderColor: "#666a73",
              "@media (max-width: 600px)": {
                py: 2,
                px: 2,
                border: 1.5,
                borderColor: "#e7e8e7",
                background: "#fff",
              },
            }}
          >
            <ListItemIcon>
              {/* <CircleIcon sx={{ color: "green" }} size="large" /> */}
              &nbsp;When can people book this event?
              <Tooltip title="Info">
                <InfoIcon sx={{ fontSize: "15px", mx: 1, mt: 0.5 }} />
              </Tooltip>
            </ListItemIcon>
            <Button
              type="submit"
              variant="contained"
              sx={{
                borderRadius: "20px",
                py: 1,
                float: "right",
                "@media (max-width: 600px)": {
                  display: "none",
                },
              }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                Next
              </Typography>
            </Button>
            <Button
              variant="text"
              sx={{
                mx: 2,
                borderRadius: "20px",
                py: 1,
                float: "right",
                "@media (max-width: 600px)": {
                  display: "none",
                },
              }}
              onClick={() => navigate("/create-event", { replace: false })}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Cancel
              </Typography>
            </Button>
            <br /> <br />
            <Divider />
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <b style={{ fontSize: "13px", fontWeight: "700" }}>
                    Date Range*
                  </b>
                  <PopupBox
                    desc={`Enter a name for your event. The circled text in the
                    screen shot below is the event name.`}
                  />
                  <br />

                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="de"
                  >
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        id="StartDate"
                        label="Start Date"
                        value={dayjs(formData.StartDate) || null}
                        onChange={(value1) => {
                          setForm({
                            ...formData,
                            StartDate: dayjs(value1),
                          });
                        }}
                        required
                      />
                      <DatePicker
                        id="EndDate"
                        label="End Date"
                        value={dayjs(formData.EndDate) || null}
                        onChange={(value1) => {
                          setForm({
                            ...formData,
                            EndDate: dayjs(value1),
                          });
                        }}
                        required
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  <b style={{ fontSize: "13px", fontWeight: "700" }}>
                    Duration*
                    <PopupBox
                      desc={`Enter a name for your event. The circled text in the
                    screen shot below is the event name.`}
                    />
                  </b>
                  <br />
                  <TextField
                    required
                    select
                    defaultValue={"30 minutes"}
                    value={formData.Duration}
                    onChange={handleChange}
                    id="Duration"
                    sx={{
                      minWidth: "63.59%",
                      mb: 2,
                      "@media (max-width: 900px)": {
                        minWidth: "100%",
                      },
                    }}
                    name="Duration"
                  >
                    <MenuItem value={"15 minutes"}>15 min</MenuItem>
                    <MenuItem value={"30 minutes"}>30 min</MenuItem>
                    <MenuItem value={"45 minutes"}>45 min</MenuItem>
                    <MenuItem value={"60 minutes"}>60 min</MenuItem>
                    <MenuItem value={"Custom"}>Custom</MenuItem>
                  </TextField>
                  <TextField
                    autoComplete="given-name"
                    name="custom-duration"
                    value={formData.customValue}
                    required
                    type="number"
                    id="custom-duration"
                    autoFocus
                    size="normal"
                    onChange={(e) => {
                      setForm({
                        ...formData,
                        customValue: e.target.value,
                      });
                    }}
                    sx={{
                      minWidth: "43.59%",
                      display: showInput,
                      "@media (max-width: 900px)": {
                        minWidth: "67.2%",
                      },
                    }}
                  />
                  &nbsp;&nbsp;
                  <TextField
                    required
                    select
                    value={formData.customUnit}
                    id="timetype"
                    onChange={(e) => {
                      setForm({
                        ...formData,
                        customUnit: e.target.value,
                      });
                    }}
                    sx={{
                      minWidth: "19%",
                      display: showInput,
                      "@media (max-width: 900px)": {
                        minWidth: "30%",
                      },
                    }}
                  >
                    <MenuItem value={"minutes"}>min</MenuItem>
                    <MenuItem value={"hours"}>hours</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <b style={{ fontSize: "13px", fontWeight: "700" }}>
                    Which schedule do you want to use?
                    <PopupBox
                      desc={`Enter a name for your event. The circled text in the
                    screen shot below is the event name.`}
                    />
                  </b>
                  <br />
                  <br />
                  <b
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                      color: "#808080",
                    }}
                  >
                    TIME ZONE
                  </b>
                  <br />

                  <Autocomplete
                    required
                    id="TimeZone"
                    options={timezome}
                    getOptionLabel={(option) => option.value}
                    filterOptions={filterOptions}
                    sx={{
                      mt: 1,
                      maxWidth: "63.59%",
                      "@media (max-width: 900px)": {
                        minWidth: "100%",
                      },
                    }}
                    value={formData.TimeZone || timezome[0]}
                    onChange={(event, newValue) => {
                      setForm({
                        ...formData,
                        TimeZone: newValue,
                      });
                    }}
                    renderInput={(params, option) => (
                      <TextField {...params} label="Select time zone" />
                    )}
                  />
                  <br />
                  <b style={{ fontSize: "13px", fontWeight: "700" }}>
                    Set your weekly hours
                  </b>
                  <br />
                  <Grid
                    sx={{
                      bgcolor: "background.paper",
                      maxWidth: "63.7%",
                      mt: 3,
                      pl: 2,
                      py: 3.0,
                      border: 1,
                      borderColor: "#ccc",
                      "@media (max-width: 900px)": {
                        minWidth: "100%",
                        border: 0,
                        pl: 1,
                      },
                    }}
                    container
                    // spacing={2}
                  >
                    {WeekDays.map((KeyData, i) => {
                      const labelId = `checkbox-list-label-${KeyData}`;

                      return (
                        <>
                          <Grid item xs={12} sm={2} div key={i + "one"}>
                            <ListItemIcon
                              // onClick={}
                              sx={{ width: "100px", pl: 0.3 }}
                            >
                              <Checkbox
                                onChange={handleToggle(KeyData)}
                                edge="start"
                                // checked={checked.indexOf(KeyData) !== -1}
                                tabIndex={i}
                                defaultChecked={FindIn(
                                  inputList[0]["DAY"],
                                  KeyData
                                )}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                              <Typography
                                sx={{
                                  mt: 1.5,
                                  fontFamily: "poppins",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {KeyData}
                              </Typography>
                            </ListItemIcon>
                          </Grid>
                          <Grid
                            id={KeyData}
                            item
                            xs={12}
                            sm={9}
                            sx={{ mb: 1 }}
                            key={i + "two"}
                          >
                            {inputList[0][KeyData].map((x, i) => {
                              return (
                                <div style={{ marginBottom: 6 }} key={i}>
                                  <TextField
                                    name="start"
                                    type="time"
                                    size="small"
                                    sx={{ width: "36%", fontSize: "12px" }}
                                    value={x.start}
                                    onChange={(e) =>
                                      handleInputChange(e, i, KeyData)
                                    }
                                    inputProps={{
                                      style: {
                                        placeholder: "",
                                        padding: "15px 10px 15px 8px",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                  &nbsp;&nbsp;
                                  <TextField
                                    name="end"
                                    type="time"
                                    size="small"
                                    sx={{ width: "36%", fontSize: "12px" }}
                                    value={x.end}
                                    onChange={(e) =>
                                      handleInputChange(e, i, KeyData)
                                    }
                                    inputProps={{
                                      style: {
                                        placeholder: "",
                                        padding: "15px 10px 15px 8px",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                  &nbsp;&nbsp;
                                  {inputList[0][KeyData].length !== 1 ? (
                                    <IconButton
                                      edge="end"
                                      sx={{ p: 0, mt: 1.5 }}
                                      aria-label="comments"
                                    >
                                      <DeleteOutlineRoundedIcon
                                        onClick={() =>
                                          handleRemoveClick(i, KeyData)
                                        }
                                      />
                                    </IconButton>
                                  ) : (
                                    <>&nbsp;&nbsp;&nbsp;</>
                                  )}
                                  &nbsp; &nbsp;&nbsp;&nbsp;
                                  {inputList[0][KeyData].length - 1 === i && (
                                    <IconButton
                                      edge="end"
                                      aria-label="comments"
                                      sx={{ p: 0, mt: 1.55 }}
                                    >
                                      <AddIcon
                                        sx={{ fontSize: "27px" }}
                                        onClick={() => {
                                          handleAddClick(i, KeyData);
                                        }}
                                      />
                                    </IconButton>
                                  )}
                                </div>
                              );
                            })}
                          </Grid>
                          <Grid item xs={12} sm={1} key={i + "three"}></Grid>
                          <Grid item xs={12} sm={12} key={i + "four"}>
                            {WeekDays.length - 1 == i ? (
                              <></>
                            ) : (
                              <>
                                <Divider sx={{ m1: 1, mb: 2 }} />
                              </>
                            )}
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                  <br />
                  <b style={{ fontSize: "13px", fontWeight: "700" }}>
                    Want to add time before or after your events?
                  </b>
                  <br />
                  <br />
                  <b
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                      marginLeft: 2,
                      color: "#808080",
                    }}
                  >
                    Before event
                  </b>
                  <br />

                  <TextField
                    select
                    id="Notification"
                    lable="Plese Select"
                    defaultValue={"30 minutes"}
                    value={formData.Notification}
                    onChange={(e) => {
                      setForm({
                        ...formData,
                        Notification: e.target.value,
                      });
                    }}
                    sx={{
                      mt: 1,
                      minWidth: "63.59%",
                      mb: 2,
                      "@media (max-width: 900px)": {
                        minWidth: "100%",
                      },
                    }}
                    name="notifiaction"
                  >
                    <MenuItem value={"15 minutes"}>15 min</MenuItem>
                    <MenuItem value={"30 minutes"}>30 min</MenuItem>
                    <MenuItem value={"45 minutes"}>45 min</MenuItem>
                    <MenuItem value={"60 minutes"}>60 min</MenuItem>
                    <MenuItem value={"90 minutes"}>1:30 hrs</MenuItem>
                    <MenuItem value={"120 minutes"}>2:00 hrs</MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />
              <Button
                onClick={(e) => {
                  handleForm(e);
                }}
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  py: 1,
                  float: "right",
                
                }}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                  Next
                </Typography>
              </Button>
              <Button
                variant="text"
                sx={{
                  mx: 2,
                  borderRadius: "20px",
                  py: 1,
                  float: "right",
                 
                }}
                onClick={() => navigate("/create-event", { replace: false })}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                  Cancel
                </Typography>
              </Button>
            </Box>
            <br /> <br />
          </Box>
        </Container>
      </form>
    </div>
  );
}
