import React from "react";

import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Typography,
  Box,
  Link
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { blue, deepPurple } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

const styles = {
  container: {
    width: "100%", // Adjust the width as needed
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
    bgcolor: "background.paper",
  },
  timeAvatar: {
    bgcolor: deepPurple[500],
    marginRight: 2,
  },
  timeText: {
    fontWeight: "bold",
    marginRight: 2,
  },
  nameText: {
    fontWeight: "bold",
    flexGrow: 1,
  },
  detailsText: {
    color: "text.secondary",
    marginRight: 2,
  },
  hostsText: {
    color: "text.secondary",
    whiteSpace: "nowrap",
  },
};

const tableCellStyle = {
  borderBottom: "none", // Removes the cell's bottom border
  paddingTop: "12px", // Adjust the padding as needed
  paddingBottom: "12px", // Adjust the padding as needed
  "&:first-of-type": {
    fontWeight: "bold", // Makes the first cell of each row bold
    // color: blue[700], // Adjust color to match the theme
    paddingRight: "16px", // Spacing between label and content
  },
  "&:last-of-type": {
    fontWeight: "normal", // Content weight
  },
  // Add other styles here to match the font and spacing
};

const linkStyle = {
  textDecoration: "none", // Removes underline from the link
  // color: blue[600], // Adjust link color to match the theme
  fontWeight: "bold", // Bold link text
};

export default function App() {
  return (
    <div className="App">
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography sx={styles.timeText}>
                  Wednesday, 5 July 2023
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box display="flex" justifyContent="center">
                <Stack direction="row" spacing={2} sx={styles.container}>
                  <Avatar sx={styles.timeAvatar}>OP</Avatar>
                  <Typography sx={styles.timeText}>
                    09:00am - 09:35am
                  </Typography>
                  <Typography sx={styles.nameText}>Sunny kumar</Typography>
                  <Typography sx={styles.detailsText}>
                    Event type dfhfds
                  </Typography>
                  <Typography sx={styles.hostsText}>
                    1 host | 0 non-hosts
                  </Typography>
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={tableCellStyle} component="th" scope="row">
                      EMAIL
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      sunnyfriend2012@gmail.com
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={tableCellStyle} component="th" scope="row">
                      LOCATION
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      This is a Google Meet web conference.{" "}
                      <Link href="#" sx={linkStyle}>
                        Join now
                      </Link>
                    </TableCell>
                  </TableRow>
                  {/* Add additional rows following the pattern above */}
                  <TableRow>
                    <TableCell sx={tableCellStyle} component="th" scope="row">
                      INVITEE TIME ZONE
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      India Standard Time
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={tableCellStyle} component="th" scope="row">
                      QUESTIONS
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      Please share anything that will help prepare for our
                      meeting. dgdsg
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={tableCellStyle} component="th" scope="row">
                      MEETING HOST
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      Host will attend this meeting
                    </TableCell>
                  </TableRow>
                  {/* Add more rows as needed */}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </Table>
      </Paper>{" "}
    </div>
  );
}
