module.exports = {
  h1: {
    fontSize: "34px",
    fontWeight: "700",
    fontFamily: "poppins",
  },
  h2: {
    mt: 2,
    fontSize: "17px",
    fontWeight: "500",
    color: "black",
    fontFamily: "poppins",
  },
  h4: {
    fontSize: "20px",
    fontWeight: "600",
    fontFamily: "poppins",
  },
  box: {
    border: 1.2,
    borderColor: "#eee",
    minHeight: "200px",
    mt: 3,
    borderRadius:"15px",
    boxShadow:
      "0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 0px 20px 0 rgba(0, 0, 0, 0.13)",
  },
  box2: {
    border: 1.2,
    borderColor: "#eee",
    minHeight: "200px",
    mt: 3,
    boxShadow:
      "0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 0px 20px 0 rgba(0, 0, 0, 0.13)",
  },

  BoxShadow:
    "0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 0px 20px 0 rgba(0, 0, 0, 0.13)",
  link: {
    textDecoration: "underline",
    color: "#0169ff",
    cursor: "pointer",
  },
};
