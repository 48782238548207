import React from "react";
import Header from "../../components/header";
import SubHeader from "../../components/subheader";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import GoogleIcon from "@mui/icons-material/Google";
import Container from "@mui/material/Container";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { pink } from "@mui/material/colors";
import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";
import StepperBlock from "../../components/stepper";
import { Typography, Tooltip, ListItemIcon, Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import PopupBox from "../../components/popupbox";
import { useBearStore } from "../../store/EventForm";
import { Styles } from "./assets/css/CreateEventStyle";

export default function CreateEvent() {
  let navigate = useNavigate();
  const { formData, setForm } = useBearStore();

  const handleChange = (event) => {
    setForm({ ...formData, EventColor: event.target.value });
  };
  const controlProps = (item) => ({
    checked: (formData.EventColor || "#0099ff") === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  function handleForm(e) {
    e.preventDefault();
    let x = document.getElementById("EventLink").value;
    setForm({
      ...formData,
      EventLink: x,
      EventColorx: "#0099ff",
    });
    navigate("/schedule", { replace: false });
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <SubHeader data={"Create New Event"} />
      <Box mt={12} />
      <form
        onSubmit={(e) => {
          handleForm(e);
        }}
      >
        <Container component="main" maxWidth="md">
          <StepperBlock no={0} />
          <Box sx={Styles.Box1}>
            <ListItemIcon>
              <CircleIcon sx={{ color: "green" }} size="large" />
              &nbsp; What is this Event?
              <Tooltip title="Info">
                <InfoIcon sx={{ fontSize: "15px", mx: 1, mt: 0.5 }} />
              </Tooltip>
            </ListItemIcon>
            <Button type="submit" variant="contained" sx={Styles.NextBtn}>
              <Typography sx={Styles.NextBtnText}>Next</Typography>
            </Button>
            <Button
              variant="text"
              sx={Styles.CancelBtn}
              onClick={() => navigate("/", { replace: false })}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Cancel
              </Typography>
            </Button>
            <br /> <br />
            <Divider />
            <Box
              // component="form"
              noValidate
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <b style={{ fontSize: "13px", fontWeight: "700" }}>
                    Event Name*
                  </b>
                  <PopupBox
                    desc={
                      "Enter a name for your event. The circled text in the screen shot below is the event name."
                    }
                  />
                  <br />
                  <TextField
                    autoComplete="given-name"
                    name="EventName"
                    value={formData.EventName}
                    onChange={(e) => {
                      setForm({ ...formData, EventName: e.target.value });
                    }}
                    required
                    fullWidth
                    id="EventName"
                    autoFocus
                    size="normal"
                  />
                </Grid>

                <Grid item xs={12}>
                  <b style={{ fontSize: "13px", fontWeight: "700" }}>
                    Location*
                    <PopupBox
                      desc={`Enter a name for your event. The circled text in the
                    screen shot below is the event name.`}
                    />
                  </b>
                  <br />
                  <TextField
                    required
                    fullWidth
                    select
                    id="Location"
                    name="Location"
                    autoComplete="Location"
                    value={formData.Location}
                    onChange={(e) => {
                      setForm({ ...formData, Location: e.target.value });
                    }}
                  >
                    {/* <MenuItem value={"In-Person Meetings"}>
                      <LocationOnIcon />
                      &nbsp;In-Person Meetings
                    </MenuItem> */}
                    {/* <MenuItem value={"Phone Call"}>
                      <PhoneIcon />
                      &nbsp;Phone Call
                    </MenuItem> */}
                    <MenuItem value={"Google Meets"}>
                      <GoogleIcon />
                      &nbsp;Google Meets
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <b style={{ fontSize: "13px", fontWeight: "700" }}>
                    Description/Instructions*
                    <PopupBox
                      desc={`Enter a name for your event. The circled text in the
                    screen shot below is the event name.`}
                    />
                  </b>
                  <br />
                  <CKEditor
                    editor={ClassicEditor}
                    data={
                      formData.Description ||
                      "<p>Write a summary and any details your invitee should know about the event.</p><br/>"
                    }
                    placeholder="Write a summary and any details your invitee should know about the event."
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ event, editor, data });
                      setForm({ ...formData, Description: data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <b style={Styles.LabelText}>
                    Event Link*
                    <PopupBox desc={`Test`} />
                  </b>
                  <br />
                  <TextField
                    autoComplete="given-name"
                    name="EventLink"
                    required
                    fullWidth
                    id="EventLink"
                    value={
                      formData.EventLink ||
                      formData?.EventName?.replaceAll(" ", "-").toLowerCase()
                    }
                    onChange={(e) => {
                      setForm({ ...formData, EventLink: e.target.value });
                    }}
                    autoFocus
                    size="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <b style={Styles.LabelText}>
                    Event Color*
                    <PopupBox desc={`Test`} />
                  </b>
                  <br />
                  <Radio
                    {...controlProps("#0099ff")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 32,
                      },
                    }}
                  />
                  <Radio
                    {...controlProps("#ffa600")}
                    // color="secondary"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 32,
                        color: "orange",
                      },
                      color: "orange",
                    }}
                  />
                  <Radio
                    {...controlProps("#17e885")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 32,
                        color: "#17e885",
                      },
                      color: "#17e885",
                    }}
                  />
                  <Radio
                    {...controlProps("#666666")}
                    color="default"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 32,
                        color: "#666666",
                      },
                      color: "#666666",
                    }}
                  />
                  <Tooltip title="Pink" arrow>
                    <Radio
                      {...controlProps("#d81b60")}
                      sx={{
                        color: "#d81b60",
                        "&.Mui-checked": {
                          color: "#d81b60",
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 32,
                        },
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2 }} />
              <Button
                type="submit"
                variant="contained"
                sx={{ my: 2, borderRadius: "20px", py: 1, float: "right" }}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                  Next
                </Typography>
              </Button>

              <Button
                variant="text"
                sx={{
                  my: 2,
                  mx: 2,
                  borderRadius: "20px",
                  py: 1,
                  float: "right",
                }}
                onClick={() => navigate("/", { replace: false })}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                  Cancel
                </Typography>
              </Button>
            </Box>
            <br /> <br />
          </Box>
        </Container>
      </form>
    </div>
  );
}
