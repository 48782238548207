export const baseUrl = "https://auth.caley.app";
export const mainUrl = "https://dash.caley.app";
// export const baseUrl = "http://localhost:3001";
// export const mainUrl = "http://localhost:5173";




// module.exports = {
//   baseUrl: baseUrl,
//   users: baseUrl + "/users",
// };
