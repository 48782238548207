import React, { useState } from "react";
import { baseUrl } from "../Apis/index";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import axios from "axios";

const RazorpayConfigModal = ({ open, handleClose, id }) => {
  const [apiKey, setApiKey] = useState("");
  // const [apiSecret, setApiSecret] = useState('');

  React.useEffect(()=>{
    axios.get(baseUrl + `/users/${id}`)
  .then(response => {
    setApiKey(response.data[0].payment_gateway_api_key)
    console.log('User data:', response.data);
  })
  .catch(error => {
    console.error('Error fetching user data:', error);
  });
  },[])

  const handleSave = async () => {
    try {
      const response = await axios.put(baseUrl + `/users/${id}`, {
        payment_gateway_api_key: apiKey,
      });
      console.log("Response:", response.data);
      // Handle the response as needed
    } catch (error) {
      console.error("Error updating user:", error);
      // Handle the error as needed
    }
  };

  const onSave = () => {
    handleSave();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Configure Razorpay
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          label="Razorpay API Key"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
        {/* <TextField
          margin="normal"
          fullWidth
          label="Razorpay API Secret"
          type="password"
          value={apiSecret}
          onChange={(e) => setApiSecret(e.target.value)}
        /> */}
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RazorpayConfigModal;
