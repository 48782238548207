import React from "react";
import { Box, AppBar, Toolbar, Typography, Button } from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { useNavigate } from "react-router-dom";

export default function SubHeader(props) {
  let navigate = useNavigate();
  return (
    <AppBar
      sx={{
        background: "#fff",
        marginTop: "65px",
        height: "60px",
        boxShadow:
          "0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 0px 20px 0 rgba(0, 0, 0, 0.13)",
        zIndex: "1",
      }}
      elevation={0}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            marginRight: "auto",
          }}
        >
          <Button
            variant="outlined"
            size={"small"}
            sx={{
              borderRadius: "25px",
              py: 0.9,
              "@media (max-width: 600px)": {},
            }}
            onClick={() => navigate(-1)}
          >
            <ChevronLeftOutlinedIcon
              size="small"
              color="black"
              sx={{ fontSize: "16px", p: 0 }}
            />
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                color: "black",
                "@media (max-width: 600px)": {
                  fontSize: "12px",
                },
              }}
            >
              Back&nbsp;
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            marginRight: "auto",
            marginLeft: "-30px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "23px",
              fontWeight: 400,
              textTransform: "none",
              color: "black",
              "@media (max-width: 600px)": {
                fontSize: "17px",
              },
            }}
          >
            {props.data}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
