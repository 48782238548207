import React, { useEffect } from "react";
import Header from "../../components/header";
import Cards from "../../components/card";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Avatar, Divider, Typography, Box, Stack, Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

// tabs
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// tabs

import { baseUrl } from "../../Apis/index";
import jwt_decode from "jwt-decode";
import { useQuery } from "react-query";
import { useBearStore } from "../../store/EventForm";
import ScheduledEvents from "./scheduled-events";


export const Dashboard = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  let navigate = useNavigate();

  // Getting Customer ID From JWT Token
  var token = localStorage.getItem("token");
  var decoded = jwt_decode(token);

  const { formData, setForm, clearForm } = useBearStore();

  const {
    data: EventData,
    isLoading,
    refetch,
  } = useQuery(
    "repoData",
    () => fetch(baseUrl + "/events?id=" + decoded.id).then((res) => res.json()),
    { enabled: true }
  );

  async function handleEdit(id) {
    const SingleEvent = await fetch(baseUrl + "/events/" + id).then((res) =>
      res.json()
    );
    setForm({ ...SingleEvent[0] });
    navigate("/create-event", { replace: false });
  }

  async function handleDelete(id) {
    const res = await fetch(baseUrl + "/events/" + id, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());
    res.affectedRows == 1 && refetch();
  }

  // Clear Form
  React.useEffect(() => {
    console.log(decoded);
    clearForm();
  }, []);

  // tabs

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // tabs end

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div>
      <Header shadow={true} />
      {/* <SubHeader /> */}
      <br />
      <div style={{ backgroundColor: "#fafafa" }}>
        <br />
        <br />

        {/* <button id="authorize_button" hidden={accessToken && expiresIn} onClick={handleAuthClick}>Authorize</button>
      <button id="signout_button" hidden={!accessToken && !expiresIn}   onClick={handleSignoutClick}>Sign Out</button>
      <button id='add_manual_event' hidden={!accessToken && !expiresIn} onClick={addManualEvent}>Add Event</button>
      <pre id="content" style={{ whiteSpace: 'pre-wrap' }}></pre> */}
        <div className="container mt-5">
          <div className="py-3">
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mx: 3 }}
            >
              <Stack direction="row">
                <Avatar src={decoded?.picture} sx={{ width: 45, height: 45 }}>
                  {decoded?.name[0]?.toUpperCase()}
                </Avatar>
                <Stack sx={{ textAlign: "start", px: 1.5, pt: 0.5 }}>
                  <Typography fontSize={13}>{decoded.name}</Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#0169ff",
                      "@media (max-width: 380px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    {/* caley.com/Sunnyfriend2013 */}
                    {decoded.email}
                  </Typography>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                size={"medium"}
                sx={{
                  borderRadius: "25px",
                  background: "#0169ff",
                  "@media (max-width: 600px)": {
                    px: 1.8,
                  },
                }}
                startIcon={
                  <AddOutlinedIcon
                    sx={{
                      "@media (max-width: 380px)": {
                        display: "none",
                      },
                    }}
                  />
                }
                onClick={() => navigate("/create-event", { replace: false })}
              >
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "13px",
                    fontWeight: 500,
                    textTransform: "none",
                    "@media (max-width: 600px)": {
                      fontSize: "10px",
                    },
                  }}
                >
                  Create
                </Typography>
              </Button>
            </Stack>
          </div>
          <Divider variant="middle" />

          <br />

          {/* <Stack
            direction={"row"}
            sx={{
              mx: 3,
              mb: 1,
              // background: "#17ad6d42",
            }}
          >
            <Box
              sx={{
                color: "#000",
                // backgroundColor: "#54be74",
                backgroundColor: "#ffffff",
                padding: "11px",
                // borderColor: "black",
                // borderRadius: "10px",
                borderBottom: 4,
                borderColor: "#0069ff",
              }}
            >
              <Typography sx={{ fontWeight: "600", fontSize: "13px" }}>
                Recent Events
              </Typography>
            </Box>
            &nbsp;&nbsp;
            <Box
              sx={{ color: "#3c4043", padding: "11px", borderRadius: "10px" }}
            >
              <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                Schaduled Event
              </Typography>
            </Box>
          </Stack> */}

          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    ".MuiTabs-indicator": {
                      height: "4px", // Thicker indicator line
                      backgroundColor: "primary.main", // Custom color for the indicator
                    },
                    border: 0, // Remove border lines of tabs
                    ".MuiTab-root": {
                      // Apply styles to each Tab
                      "&.Mui-selected": {
                        // Styles for the selected Tab
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        outline: "none", // Example style for selected state
                        // color:"#17ad6b"
                        padding: 1,
                        fontWeight: 600,
                      },
                    },
                  }}
                >
                  <Tab
                    label="Recent Events"
                    value="1"
                    sx={{ fontWeight: 500 }}
                  />
                  <Tab
                    label="Scheduled Events"
                    value="2"
                    sx={{ fontWeight: 500 }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ padding: 0, margin: 0 }}>
                <div className="row mt-3">
                  {EventData?.map((val) => {
                    return (
                      <>
                        <div className="col-lg-4 col-12 ">
                          <Cards
                            id={val.id}
                            bcolor={val.EventColor}
                            title={val.EventName}
                            duration={val.Duration}
                            location={val.Location}
                            eventlink={val.EventLink}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            decoded={decoded}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{padding:0,mt:4}}>
                <ScheduledEvents  custumerId={decoded.id}/>
              </TabPanel>
            </TabContext>
          </Box>

          {/* {SingleEvent.map((val1) => {
            return <h1>{val1.Schedule[0].MON[0].end}</h1>;
          })} */}
        </div>
      </div>
      <br /> <br /> <br />
    </div>
  );
};
