import { baseUrl } from "../Apis/index";
import jwt_decode from "jwt-decode";




export async function getUsetInfo() {
  // Getting Customer ID From JWT Token
var token = localStorage.getItem("token");
var decoded = jwt_decode(token);
  // console.log(baseUrl);
  try {
    const response = await fetch(baseUrl + "/users/"+decoded.id);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    // console.log(data[0]);
    return data[0];
  } catch (error) {
    console.error("Error:", error);
  }
}
