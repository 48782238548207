import React from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Android12Switch } from "./component/Switch";
import {
  Typography,
  Tooltip,
  ListItemIcon,
  Divider,
  Container,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ViewStreamOutlinedIcon from "@mui/icons-material/ViewStreamOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";

import { useBearStore } from "../../store/EventForm";
import { PostData, UpdateData } from "../../Apis/EventForm";

import Header from "../../components/header";
import SubHeader from "../../components/subheader";
import PopupBox from "../../components/popupbox";
import StepperBlock from "../../components/stepper";
import RazorpayConfigModal from "../../components/PaymentGatewayConfig";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

export default function FinalPage() {
  const [modalOpen, setModalOpen] = React.useState(false);
 

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);


  let navigate = useNavigate();
  const { formData, setForm, clearForm } = useBearStore();

  function handlePayment(e, gateway) {
    setForm({
      ...formData,
      Payment: gateway,
    });
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);

    // Getting Customer ID From JWT Token
    var token = localStorage.getItem("token");
    var decoded = jwt_decode(token);

    // SET ID TO GLOBAL STATE
    setForm({
      ...formData,
      BasicQuestion: true,
      CustomerId: decoded.id,
      // currency_type: "INR",
      // Payment:"na",
    });
  }, []);

  // Handel Form DATA
  const EditForm = formData.id ? true : false;
  async function handleFinalForm() {
    let res;
    if (EditForm) {
      res = await UpdateData(formData, formData.id);
    } else {
      res = await PostData(formData);
    }
    res.affectedRows == 1 && clearForm();
    navigate("/dashboard", { replace: false });
  }

  const [currency, setCurrency] = React.useState("INR");

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
    setForm({
      ...formData,
      currency_type: event.target.value,
    });
  };

  return (
    <div>
      <Header />
      <SubHeader data={"Final Page"} />
      <Box mt={12} />
      <Container component="main" maxWidth="md">
        <StepperBlock no={2} />
        <Box
          sx={{
            marginTop: 4,
            marginBottom: 8,
            py: 3,
            px: 6,
            background: "#fff",
            border: 2.1,
            borderRadius: "3px",
            borderColor: "#666a73",
            "@media (max-width: 600px)": {
              py: 2,
              px: 2,
              border: 1.5,
              borderColor: "#e7e8e7",
              background: "#fff",
            },
          }}
        >
          <ListItemIcon>
            &nbsp; Additional Options
            <Tooltip title="Info">
              <InfoIcon sx={{ fontSize: "15px", mx: 1, mt: 0.5 }} />
            </Tooltip>
          </ListItemIcon>
          <br /> <br />
          <Divider />
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Accordion
              sx={{ border: 1.2, borderColor: "#ccc", mb: 1 }}
              elevation={0}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="row">
                  <div className="col-1">
                    <ViewStreamOutlinedIcon />
                  </div>
                  <div className="col-11">
                    <b>Invitee Questions</b>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-11">
                    {" "}
                    <p>Name, Email + questions</p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    pr: 25,
                    "@media (max-width: 600px)": {
                      pr: 0,
                    },
                  }}
                >
                  <b
                    style={{
                      fontSize: "14px",
                      marginBottom: "-15px",
                      fontWeight: "700",
                    }}
                  >
                    &nbsp;&nbsp;Basic Questions
                  </b>
                  <b style={{ mb: 4 }}>
                    <Android12Switch
                      checked={true}
                      onChange={(e) => {
                        setForm({
                          ...formData,
                          BasicQuestion: e.target.checked,
                        });
                      }}
                      sx={{ float: "right" }}
                    />{" "}
                  </b>
                  <Grid
                    container
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      background: "rgba(24,174,109,0.09)",
                    }}
                  >
                    <Grid item xs={12} sm={5.5} mr={2}>
                      <b style={{ fontSize: "13px", fontWeight: "700" }}>
                        First Name *
                      </b>
                      <PopupBox desc={"sample text ...."} />
                      <br />
                      <TextField
                        sx={{ background: "#fff" }}
                        fullWidth
                        required
                        id="firstname"
                        name="firstname"
                        disabled
                        autoComplete="first-name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={5.5}>
                      <b style={{ fontSize: "13px", fontWeight: "700" }}>
                        Last Name *
                      </b>

                      <PopupBox desc={"sample text ...."} />
                      <br />
                      <TextField
                        sx={{ background: "#fff" }}
                        required
                        id="lastname"
                        name="lastname"
                        autoComplete="last-name"
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={11.4}>
                      <b style={{ fontSize: "13px", fontWeight: "700" }}>
                        Email *
                      </b>
                      <PopupBox desc={"sample text ...."} />
                      <br />
                      <TextField
                        sx={{ background: "#fff" }}
                        required
                        id="email"
                        name="email"
                        autoComplete="email"
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={11.4}>
                      <b style={{ fontSize: "13px", fontWeight: "700" }}>
                        {" "}
                        Please share anything that will help prepare for our
                        meeting.
                      </b>
                      <PopupBox desc={"sample text ...."} />
                      <TextField
                        sx={{ background: "#fff" }}
                        required
                        id="desc"
                        name="desc"
                        autoComplete="desc"
                        multiline
                        rows={3}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box my={2} />
                <Box
                  sx={{
                    pr: 25,
                    "@media (max-width: 600px)": {
                      pr: 0,
                    },
                  }}
                >
                  <b
                    style={{
                      fontSize: "14px",
                      marginBottom: "-15px",
                      fontWeight: "700",
                    }}
                  >
                    &nbsp;&nbsp;Additional Questions
                  </b>
                  <b style={{ mb: 4 }}>
                    <Android12Switch
                      sx={{ float: "right" }}
                      onChange={(e) => {
                        setForm({
                          ...formData,
                          AdditionalQuestion: e.target.checked,
                        });
                      }}
                    />{" "}
                  </b>
                  <Grid
                    container
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      background: "rgba(24,174,109,0.09)",
                    }}
                  >
                    <Grid item xs={12} sm={11.4}>
                      <b style={{ fontSize: "13px", fontWeight: "700" }}>
                        Mobile No *
                      </b>
                      <PopupBox desc={"sample text ...."} />
                      <br />
                      <TextField
                        sx={{ background: "#fff" }}
                        required
                        id="mobileno"
                        name="mobileno"
                        autoComplete="mobileno"
                        disabled
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ border: 1.2, borderColor: "#ccc", mb: 1 }}
              elevation={0}
            >
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <div className="row">
                  <div className="col-1">
                    <PaymentOutlinedIcon />
                  </div>
                  <div className="col-11">
                    <b>Collect Payments</b>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-11">
                    {" "}
                    <p>Select Payment method</p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 6 }}>
                <div className="row">
                  {/* <div className="col-12">
                    <b>Stripe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                    <Android12Switch
                      checked={formData.Payment == "Stripe" ? true : false}
                      onChange={(e) => {
                        handlePayment(e, "Stripe");
                      }}
                    />
                    <a href="#" onClick={handleOpen}>Configure</a>
                  </div> */}

                  <div className="col-12">
                    <b>Razopay</b>
                    <Android12Switch
                      checked={formData.Payment == "Razopay" ? true : false}
                      onChange={(e) => {
                        handlePayment(
                          e,
                          formData.Payment == "Razopay" ? "na" : "Razopay"
                        );
                      }}
                    />{" "}
                    <a href="#" onClick={handleOpen}>
                      Configure
                    </a>
                  </div>
                  {formData.Payment == "na" ? (
                    <></>
                  ) : (
                    <div style={{ margin: "20px" }}>
                      <p style={{ my: "15px" }}>Amount to be collected *</p>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          type="number"
                          variant="outlined"
                          defaultValue={formData.event_fee||0}
                          inputProps={{ min: 0 }}
                          style={{ marginRight: "10px", width: "100px" }}
                          onChange={(e) => {
                            setForm({
                              ...formData,
                              event_fee: e.target.value,
                            });
                          }}
                        />
                        <FormControl
                          variant="outlined"
                          style={{ minWidth: "100px" }}
                        >
                          <InputLabel>Currency</InputLabel>
                          <Select
                            onChange={handleCurrencyChange}
                            defaultValue={formData.currency||"INR"}
                            label="Currency"
                          >
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="EUR">EUR</MenuItem>
                            <MenuItem value="INR">INR</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div style={{ marginTop: "20px" }}>
                      <Typography variant="h6" gutterBottom>
                        Payment terms
                      </Typography>
                      <TextField
                        multiline
                        rows={4}
                        variant="outlined"
                        defaultValue="Refunds will not be issued for cancellations with less than 24 hours advanced notice"
                        fullWidth
                      />
                    </div> */}
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>

            <Divider sx={{ mt: 2 }} />
            <Button
              variant="contained"
              sx={{ my: 2, borderRadius: "20px", py: 1, float: "right" }}
              onClick={() => {
                handleFinalForm();
              }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                {EditForm ? "Update" : "Share"}
              </Typography>
            </Button>

            <Button
              variant="text"
              sx={{
                my: 2,
                mx: 2,
                borderRadius: "20px",
                py: 1,
                float: "right",
              }}
              onClick={() => navigate("/schedule", { replace: false })}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Back
              </Typography>
            </Button>
          </Box>
          <br /> <br />
        </Box>
        <RazorpayConfigModal
          open={modalOpen}
          handleClose={handleClose}
          id={formData.CustomerId}
        />
      </Container>
    </div>
  );
}
