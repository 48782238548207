import React, { useState } from "react";
import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./Drawer.js";
import Logo from "../images/logo1.png";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { PropaneSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const Header = (props) => {
  const [value, setValue] = useState();
  const gapi = window.gapi;
  const google = window.google;
  let navigate = useNavigate();

  // Getting Customer ID From JWT Token
  var token = localStorage.getItem("token");
  var decoded = jwt_decode(token);
  // console.log(decoded)

  const theme = useTheme();
  console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMatch);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let shadow = props.shadow;

  function handleSignoutClick() {
    // const token = gapi.client.getToken();
    const token = localStorage.getItem("token");
    if (token !== null) {
      // google.accounts.oauth2.revoke(token.access_token);
      // gapi.client.setToken("");
      localStorage.clear();
      navigate("/login", { replace: false });
    }
  }

  return (
    <React.Fragment>
      <AppBar
        sx={{
          background: "#fff",
          borderBottom: 1,
          borderColor: "#cccccc",
          boxShadow: shadow
            ? "0 0px 3px 0 rgba(0, 0, 0, 0.12), 0 0px 20px 0 rgba(0, 0, 0, 0.13)"
            : "",
        }}
        elevation={0}
      >
        <Toolbar>
          <img
            src={Logo}
            style={{cursor:"pointer"}}
            width="100px"
            onClick={() => {
              navigate("/", { replace: false });
            }}
          />
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              {/* <Tabs
                sx={{ marginLeft: "auto" }}
                indicatorColor="secondary"
                textColor="inherit"
                value={value}
                onChange={(e, value) => setValue(value)}
              >
                <Tab label="Home" />
                <Tab label="Integration" />
                <Tab label="Help" />
              </Tabs> */}
              {/* <Button sx={{ marginLeft: "auto" }} variant="contained">
                Login
              </Button> */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  marginLeft: "auto",
                }}
              >
                <Typography
                  sx={{
                    minWidth: 100,
                    fontWeight: 700,
                    fontSize: 14,
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/dashboard", { replace: false });
                  }}
                >
                  Home
                </Typography>
                <Typography
                  sx={{
                    minWidth: 100,
                    fontWeight: 500,
                    fontSize: 14,
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/integrations", { replace: false });
                  }}
                >
                  Integration
                </Typography>
                <Typography
                  sx={{
                    minWidth: 100,
                    fontWeight: 500,
                    fontSize: 14,
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  Help
                  {decoded?.pictute}
                </Typography>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      src={decoded?.picture}
                      sx={{ width: 32, height: 32 }}
                    >
                      {decoded?.name[0]?.toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    borderRadius: 3,
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.22))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {/* <MenuItem onClick={handleClose}>
                  <Avatar /> Profile
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    navigate("/account", { replace: false });
                  }}
                >
                  <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleSignoutClick}>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Add another account
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem> */}
                <MenuItem onClick={handleSignoutClick}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
