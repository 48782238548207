import {baseUrl} from "../Apis/index";

export const PostData = (data) => {
  return fetch(baseUrl+"/events/", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((res) => res.json());
  // .then((data) => console.log(data));
};

export const UpdateData = (data, id) => {
  return fetch(baseUrl+"/events/" + id, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((res) => res.json());
  // .then((data) => console.log(data));
};

export const fetchData = (id) => {
  return fetch(baseUrl+"/events/" + id).then((res) => res.json());
  // .then((data) => console.log(data));
};
