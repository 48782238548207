import React, { useEffect } from "react";
import {
  FormHelperText,
  Button,
  TextField,
  Stack,
  Typography,
  Grid,
  Card,
  Box,
  Container,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import UploadAvatar from "./uploadavtar";
import Header from "../../components/header";
import SubHeader from "../../components/subheader";
import timezome from "../../timezones.json";
import { getUsetInfo } from "../../Controller/usersController";

const Styles = require("../../style/index.js");
const top100Films = timezome;

export default function Account() {
  const [timezoneValue, setTimezone] = React.useState(null);
  const [userInfo, setUserData] = React.useState({ name: "", email: "" });

  useEffect(() => {
    getUsetInfo().then((value) => setUserData(value));
    console.log("userInfo.name");

    console.log(userInfo.email);
  }, []);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.value,
  });

  return (
    <div>
      <Header />
      <SubHeader data={"My Account"} />
      <br />
      <br />
      <br />
      <br />

      <Container component="main" maxWidth="lg" sx={{ mt: 10 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card
              elevation={0}
              sx={{
                py: 10.25,
                px: 3,
                boxShadow: Styles.BoxShadow,
                borderRadius: 3,
              }}
            >
              <Box sx={{ mb: 5 }}>
                <UploadAvatar
                  accept="image/*"
                  // file={values.avatarUrl}
                  maxSize={3145728}
                  // onDrop={handleDrop}
                  // error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                  caption={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: "auto",
                        display: "block",
                        textAlign: "center",
                        color: "text.secondary",
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of
                    </Typography>
                  }
                />
                <FormHelperText error sx={{ px: 2, textAlign: "center" }}>
                  {/* {touched.avatarUrl && errors.avatarUrl} */}
                </FormHelperText>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card
              elevation={0}
              sx={{ p: 3, boxShadow: Styles.BoxShadow, borderRadius: 3 }}
            >
              <Stack spacing={3}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    defaultValue={userInfo.name}
                    value={userInfo.name}
                    onChange={(e) => {
                      setUserData({ ...userInfo, name: e.target.value });
                    }}
                    fullWidth
                    label="Full Name"
                  />
                  <TextField
                    fullWidth
                    defaultValue={userInfo.email}
                    value={userInfo.email}
                    onChange={(e) => {
                      setUserData({ ...userInfo, email: e.target.value });
                    }}
                    label="Email Address"
                  />
                </Stack>

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField fullWidth label="Phone Number" />

                  <TextField
                    select
                    fullWidth
                    label="Country"
                    placeholder="Country"
                    SelectProps={{ native: true }}
                  >
                    <option value="" />
                    {[{ label: "India" }].map((option) => (
                      <option key={option.code} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Stack>

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField fullWidth label="State/Region" />
                  <TextField fullWidth label="City" />
                </Stack>

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField fullWidth label="Address" />
                  <Autocomplete
                    fullWidth
                    id="filter-demo"
                    options={top100Films}
                    getOptionLabel={(option) => option.value}
                    filterOptions={filterOptions}
                    sx={{
                      mt: 1,
                    }}
                    value={timezoneValue}
                    onChange={(event, newValue) => {
                      setTimezone(newValue);
                    }}
                    renderInput={(params, option) => (
                      <TextField {...params} label="Select time zone" />
                    )}
                  />
                </Stack>

                <Box
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={{
                      mx: 2,
                      borderRadius: "20px",
                      py: 1,
                      float: "right",
                    }}
                    //   onClick={() => navigate("/", { replace: false })}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                      Cancel
                    </Typography>
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ borderRadius: "20px", py: 1 }}
                    // onClick={() => navigate("/schedule", { replace: false })}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      Save
                    </Typography>
                  </Button>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
