export const Styles = {
  Box1: {
    marginTop: 4,
    marginBottom: 8,
    py: 3,
    px: 6,
    background: "#fff",
    border: 2.1,
    borderRadius: "3px",
    borderColor: "#666a73",
    "@media (max-width: 600px)": {
      py: 2,
      px: 2,
      border: 1.5,
      borderColor: "#e7e8e7",
      background: "#fff",
    },
  },
  NextBtn: {
    borderRadius: "20px",
    py: 1,
    float: "right",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  NextBtnText: { fontSize: "12px", fontWeight: "600" },
  CancelBtn: {
    mx: 2,
    borderRadius: "20px",
    py: 1,
    float: "right",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  LabelText: { fontSize: "13px", fontWeight: "700" },
};
