import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
  Switch,
} from "@mui/material";

import React from "react";
import Logo from "../../images/logo1.png";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
const Styles = require("../../style/index.js");

export default function AddAccount() {
  let navigate = useNavigate();
  return (
    <div>
      <Container component="main" maxWidth="sm">
        <div className="text-center mt-5">
          <br />
          <Typography sx={Styles.h1}>
            <img src={Logo} width="200px" />
          </Typography>
          <Typography sx={Styles.h2}>
            This will automatically connect your calendar so you can start using
            Kelly right away!
          </Typography>
        </div>
        <Box sx={Styles.box2}>
          <Grid container spacing={2} sx={{ px: 3, py: 5 }}>
            <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
              <br />
              <Button
                sx={{ borderRadius: 3, cursor: "pointer" }}
                startIcon={<GoogleIcon />}
                variant="outlined"
                size={"large"}
              >
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "17px",
                    fontWeight: 500,
                    py: 0.7,
                    textTransform: "none",
                    "@media (max-width: 600px)": {
                      fontSize: "10px",
                    },
                  }}
                >
                  Add Your Google Account
                </Typography>
              </Button>
              <br />
              <br />
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  py: 0.7,
                  textTransform: "none",
                  "@media (max-width: 600px)": {
                    fontSize: "10px",
                  },
                }}
              >
                Don't have an account?{" "}
                <span
                  style={Styles.link}
                  onClick={() => navigate("/signup", { replace: false })}
                >
                  Sign up.
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
