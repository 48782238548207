import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";

import { baseUrl } from "../../Apis/index";
import React, { useState, useEffect } from 'react';
import Logo from "../../images/logo1.png";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
const Styles = require("../../style/index.js");
const ApiUrl = require("../../Apis/index");


export default function SignupPage() {
  let navigate = useNavigate();

  React.useEffect(() => {
    const token = localStorage.getItem("token")&&"";
    if (token !== null) {
      navigate("/dashboard", { replace: false });
    }
  }, []);


  // new implementation
  const [tokens, setTokens] = useState(null);
  function glogin(){
     // Fetch the URL for Google OAuth
     axios.get(baseUrl+'/auth/google/url')
     .then(response => {
       const authUrl = response.data.url;
       window.location.href = authUrl;
     })
     .catch(error => console.error('Error fetching auth URL', error));
  }

    useEffect(() => {
      // Handle authentication callback from Google
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get('access_token');
      const refreshToken = urlParams.get('refresh_token');
      if (accessToken && refreshToken) {
        
        setTokens({ access_token: accessToken, refresh_token: refreshToken });
        // Clear URL parameters
        window.history.pushState({}, document.title, window.location.pathname);
        console.log(accessToken);
        getUsetInfo(accessToken,refreshToken)
      }
    }, []);
  
    const handleCreateMeet = () => {
      if (!tokens) return;
      // Request the server to create a Google Meet event
      axios.post(baseUrl+'/create-meet', { accessToken: tokens.access_token })
        .then(response => {
          console.log('Meet link created:', response.data.hangoutLink);
          // Do something with the Meet link
        })
        .catch(error => console.error('Error creating Meet link', error));
    };
  // new implementation



  // create user
  async function CreateUser(userInfo, token,refresh_token) {

    const currentDateTimeUTC = Date.now();
    const data = {
      name: userInfo.name,
      email: userInfo.email,
      phoneno: "",
      accessToken: token,
      expiresIn:currentDateTimeUTC,
      picture: userInfo.picture,
      sub: userInfo.sub,
      refresh_token:refresh_token
    };
    try {
      const response = await fetch(baseUrl+"/users", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const responseData = await response.json();
      localStorage.setItem("token", responseData.token);
      navigate("/dashboard", { replace: false });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function getUsetInfo(token,refresh_token) {
    const url = `https://www.googleapis.com/oauth2/v3/userinfo`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await fetch(url, { headers });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      CreateUser(data, token,refresh_token);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div>
      <Container component="main" maxWidth="sm">
        <div className="text-center mt-5">
          <br />
          <Typography sx={Styles.h1}>
            <img src={Logo} width="225px" />
          </Typography>
          <br/>
          <Typography sx={Styles.h2}>
            Sign up With caley And Appointments Become Easy
          </Typography>
        </div>
        <Box sx={Styles.box}>
          <Grid container spacing={2} sx={{ px: 3, pt: 4 }}>
            {/* <Grid item xs={12} sm={12}>
              <b style={{ fontSize: "13px", fontWeight: "600" }}>
                Enter Your email to get started.
              </b>
              <br />
              <TextField
                inputProps={{
                  style: {
                    fontFamily: "poppins",
                    fontSize: "20px",
                    border: 1.2,
                    borderColor: "#ccc",
                  },
                }}
                autoComplete="given-name"
                variant="outlined"
                name="Event Link"
                required
                fullWidth
                id="Event Link"
                // label="Event Link"
                autoFocus
                size="normal"
              />
            </Grid> */}
            <Grid item xs={12} sm={12}>
            <br/>
              {/* <Button
                variant="contained"
                size={"large"}
                sx={{ borderRadius: 3 }}
                fullWidth
                onClick={() => {
                  navigate("/add-google-account", { replace: false });
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "17px",
                    fontWeight: 500,
                    py: 0.7,
                    textTransform: "none",
                    "@media (max-width: 600px)": {
                      fontSize: "10px",
                    },
                  }}
                >
                  Get Started
                </Typography>
              </Button>
              <br /> <br />
              <div class="separator">
                <div class="line"></div>
                <h4
                  style={{
                    color: "#aaa",
                    fontFamily: "poppins",
                    fontSize: "17px",
                  }}
                >
                  OR
                </h4>
                <div class="line"></div>
              </div>
              <br /> */}
              <Button
                sx={{ borderRadius: 3 }}
                startIcon={<GoogleIcon />}
                variant="outlined"
                size={"large"}
                fullWidth
                id="authorize_button"
             
                onClick={glogin}
              >
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "17px",
                    fontWeight: 500,
                    py: 0.7,
                    textTransform: "none",
                    "@media (max-width: 600px)": {
                      fontSize: "10px",
                    },
                  }}
                >
                 Login with Google
                </Typography>
              </Button>
              <br />
              <br />
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  py: 0.7,
                  textTransform: "none",
                  "@media (max-width: 600px)": {
                    fontSize: "10px",
                  },
                }}
              >
                Don't have an account?{" "}
                <span
                  style={Styles.link}
                  onClick={() => navigate("/", { replace: false })}
                >
                  Sign up.
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
