import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Link } from "react-router-dom";

const steps = [
  { title: "Create Event", link: "create-event" },
  { title: "Schedule Event", link: "schedule" },
  { title: "Booking Final", link: "final-page" },
];

export default function HorizontalLabelPositionBelowStepper(props) {
  return (
    <>
      <br />
      <br />
      <br />
      <Stepper activeStep={props.no} alternativeLabel>
        {steps.map((obj) => (
          <Step key={obj.title}>
            <StepLabel>
              <Link style={{color:"black"}} to={"/"+obj.link}>{obj.title}</Link>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
}
