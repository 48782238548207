import * as React from "react";
import jwt_decode from "jwt-decode";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import RazorpayConfigModal from "../../components/PaymentGatewayConfig";

import { Typography, Tab, Tabs, Box, Button, Grid } from "@mui/material";
import PropTypes from "prop-types";
import Header from "../../components/header";
import SubHeader from "../../components/subheader";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery"
;

import Img1 from "../../images/stripe.png";
import Img2 from "../../images/paypal.png";
import Img3 from "../../images/razorpay-icon.png";
import Img4 from "../../images/wordpress-icon.png";
// import Img4 from "../images/wordpress-icon.png";

const Styles = require("../../style/index.js");

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function Integrations() {

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const [value, setValue] = React.useState(0);
  const matches = useMediaQuery("(min-width:600px)");
  let condTabOrientation;

  if (matches) {
    condTabOrientation = "vertical";
  } else {
    condTabOrientation = "horizontal";
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var token = localStorage.getItem("token");
  var decoded = jwt_decode(token);

  return (
    <>
      <Header />
      <SubHeader data={"Integrations"} />
      <br />
      <br />
      <br />
      <br />
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            mt: 10,
            "@media (max-width: 480px)": {
              mt: 6,
              flexGrow: 1,
              display: "block",
            },
          }}
        >
          <Tabs
            orientation={condTabOrientation}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              minWidth: "180px",
            }}
          >
            <Tab
              label={
                <span
                  style={{
                    fontSize: "14px",
                    textTransform: "none",
                    fontFamily: "poppins",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  Payments
                </span>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <span
                  style={{
                    fontSize: "14px",
                    textTransform: "none",
                    fontFamily: "poppins",
                    fontWeight: "500",
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  Embed caley
                </span>
              }
              {...a11yProps(0)}
            />
          </Tabs>

          <TabPanel value={value} index={0}>
            <h4 style={Styles.h4}>Payments</h4>
            <br />
            <Grid container spacing={2} sx={{ px: 2 }}>
              <Grid xs={12} md={4} sx={{ p: 1.5 }}>
                <Card variant="outlined">
                  <>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14, ml: -2 }}
                        color="text.secondary"
                      >
                        <img src={Img1} width={"150px"} />
                      </Typography>
                      <Typography
                        sx={{ mb: 1.0, fontFamily: "poppins" }}
                        color="text.secondary"
                      >
                        Stripe
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "poppins" }}
                      >
                        Collect Payment for your
                        <br /> Meetings
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small">Comming Soon</Button>
                    </CardActions>
                  </>
                </Card>
              </Grid>

              <Grid xs={12} md={4} sx={{ p: 1.5 }}>
                <Card variant="outlined">
                  <>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14, mb: 0.8 }}
                        color="text.secondary"
                      >
                        <img src={Img2} width={"65px"} />
                      </Typography>
                      <Typography
                        sx={{ mb: 1.0, fontFamily: "poppins" }}
                        color="text.secondary"
                      >
                        Paypal
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "poppins" }}
                      >
                        Collect Payment for your
                        <br /> Meetings
                      </Typography>
                    </CardContent>
                    <CardActions>
                    <Button size="small">Comming Soon</Button>
                    </CardActions>
                  </>
                </Card>
              </Grid>
              <Grid xs={12} md={4} sx={{ p: 1.5 }}>
                <Card variant="outlined">
                  <>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14, py: 2.2 }}
                        color="text.secondary"
                      >
                        <img src={Img3} width={"165px"} />
                      </Typography>
                      <Typography
                        sx={{ mb: 1.0, fontFamily: "poppins" }}
                        color="text.secondary"
                      >
                        Razorpay
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "poppins" }}
                      >
                        Collect Payment for your
                        <br /> Meetings
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button  onClick={handleOpen} size="small">Connect</Button>
                    </CardActions>
                  </>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h4 style={Styles.h4}>Embed Kelly</h4>
            <br />
            <Grid container spacing={2} sx={{ px: 2 }}>
              <Grid xs={12} md={12} sx={{ p: 1.5 }}>
                <Card variant="outlined">
                  <>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14, mb: 2 }}
                        color="text.secondary"
                      >
                        <img src={Img4} width={"100px"} />
                      </Typography>
                      <Typography
                        sx={{ mb: 1.0, fontFamily: "poppins" }}
                        color="text.secondary"
                      >
                        WordPress
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "poppins" }}
                      >
                        Embed Your Booking Page on
                        <br /> Your Website
                      </Typography>
                    </CardContent>
                    <CardActions>
                    <Button size="small">Comming Soon</Button>
                    </CardActions>
                  </>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
        <RazorpayConfigModal
          open={modalOpen}
          handleClose={handleClose}
          id={decoded.id}
        />
      </Container>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
