import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { baseUrl ,mainUrl} from "../Apis/index";

import toast, { Toaster } from "react-hot-toast";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import Tooltip from "@mui/material/Tooltip";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import { Stack, IconButton, Divider, Grid, Switch } from "@mui/material";
import MyModal from "./ShareModal";

export default function Cards(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleOpen = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  return (
    <React.Fragment>
      <Card
        sx={{
          borderTop: 3,
          borderColor: props.bcolor,
          marginTop: "15px",
          borderRadius: "4px",
        }}
      >
        <Grid container>
          <Grid item xs={2}>
            {/* <Box>
              <CustomizedCheckbox />
            </Box> */}
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={4} sx={{ textAlign: "end", paddingRight: "5px" }}>
            <Box sx={{ mt: 0.6 }}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <SettingsOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              //   onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  props.handleEdit(props.id);
                }}
              >
                <ListItemIcon>
                  <EditOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Edit
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <TextSnippetOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Add Internal Note
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </MenuItem>
              <MenuItem
                onClick={() => {
                  props.handleDelete(props.id);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Delete
              </MenuItem>
              <Divider />
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    textTransform: "none",
                    px: 2,
                    pt: 0.3,
                  }}
                >
                  On/Off
                </Typography>
                <Switch
                  value="checkedA"
                  inputProps={{ "aria-label": "Switch A" }}
                  size="small"
                  sx={{ mr: 1 }}
                />
              </Stack>
            </Menu>
          </Grid>
          <Grid item xs={8} sx={{ textAlign: "start", marginLeft: "20px" }}>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "1.6em",
              }}
            >
              {props.title}
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "13.5px",
                fontWeight: 400,
                color: "#737373",
              }}
            >
              {props.duration}, Meeting on
              <br />
              {props.location}
            </Typography>
            <Typography
              onClick={() => {
                navigate(
                  "/" +
                    props.decoded.name.replaceAll(" ", "-") +
                    "/" +
                    props.eventlink +
                    "/" +
                    props.id,
                  {
                    replace: false,
                  }
                );
              }}
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 400,
                color: "#0169ff",

                py: 2,
                "&:hover": {
                  textDecoration: "underline",
                  cursor: "pointer",
                },
              }}
            >
              View booking page
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, py: 1, bgcolor: "background.default" }}
        >
          <Button
            size="small"
            // variant="text"
            style={{
              outline: "none", // Remove the outline on active (click)
              boxShadow: "none", // Remove the box-shadow on active (click)
            }}
            onClick={() => {
              navigator.clipboard
                .writeText(
                  mainUrl +
                    "/" +
                    props.decoded.name.replaceAll(" ", "-") +
                    "/" +
                    props.eventlink +
                    "/" +
                    props.id
                )
                .then(() => {
                  toast.success("Link Copied!");
                })
                .catch((err) => {
                  console.error("Could not copy text: ", err);
                });
            }}
          >
            <ContentCopyOutlinedIcon sx={{ fontSize: "16px" }} />
            &nbsp;
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 400,
                textTransform: "none",
              }}
            >
              Copy Link
            </Typography>
          </Button>
          <Button
            onClick={handleOpen}
            variant="outlined"
            sx={{ borderRadius: "20px" }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 400,
                textTransform: "none",
              }}
            >
              Share
            </Typography>
          </Button>
          <Toaster />
        </Stack>
      </Card>

      <MyModal
        open={open2}
        handleClose={handleClose2}
        link={
          mainUrl+"/"+
          props.decoded.name.replaceAll(" ", "-") +
          "/" +
          props.eventlink +
          "/" +
          props.id
        }
        title={props.title}
      />
    </React.Fragment>
  );
}
