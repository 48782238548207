import "./App.css";
import { Dashboard } from "./pages/Dashboard/Index";
import TimeSlot from "./pages/CreateEvents/TimeSlot";
import CreateEvent from "./pages/CreateEvents/CreateEvent";
import FinalPage from "./pages/CreateEvents/FinalPage";
import SignupPage from "./pages/Auth/SignupPage";
import LoginPage from "./pages/Auth/LoginPage";
import Account from "./pages/Accounts/Account";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddAccount from "./pages/Accounts/AddAccount";
import Integrations from "./pages/Integration/Integrations";
import PrivateRoutes from "./utils/PrivateRoutes";
import Calender from "./pages/Calender/dynamicPages";
import Index from "./pages/TestingComp/index";

const isAuthenticated = false;

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignupPage />} />
        <Route element={<PrivateRoutes />}>
          <Route element={<Dashboard />} path="/dashboard" />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/account" element={<Account />} />
          <Route path="/create-event" element={<CreateEvent />} />
          <Route path="/schedule" element={<TimeSlot />} />
          <Route path="/final-page" element={<FinalPage />} />
          <Route path="/add-google-account" element={<AddAccount />} />
          <Route path="/integrations" element={<Integrations />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/testing" element={<Index />} />
        <Route path="/:userLink/:eventLink/:id" element={<Calender />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
