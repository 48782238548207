import React from "react";
import {
  Typography,
  Tooltip,
  ListItemIcon,
  IconButton,
  Chip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";

export default function PopupBox(props) {
  const [show, setShow] = React.useState(false);
  return (
    <Tooltip
      open={show}
      onClick={() => setShow(true)}
      // onMouseLeave={() => setShow(false)}
      arrow
      title={
        <div style={{ padding: "7px", paddingBottom: "0px" }}>
          <p
            style={{
              color: "white",
              fontSize: "14px",
              fontFamily: "poppins",
            }}
          >
            {props.desc}
          </p>

          <Chip
            label="Got It"
            variant="outlined"
            sx={{ marginBottom: "10px", color: "#fff" }}
            onClick={() => setShow(false)}
          />
        </div>
      }
      placement="right"
    >
      <InfoIcon
        sx={{
          fontSize: "15px",
          mx: 1,
          mt: 0.9,
          color: "#808080",
          cursor: "pointer",
        }}
      />
    </Tooltip>
  );
}
